<template>
    <div class="my-4 w-full text-center">
        Signing out...
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    mounted() {
        this.checkout()

        this.logout()
            .then(() => {
                // Redirect to Login
                this.$router.replace({
                    name: 'Login',
                })
            })
            .catch(() => {
                // Do nothing
                this.$toast.error('Could not logout. Unknown error.')
            })
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
        }),

        ...mapActions({
            checkout: 'patient/checkOutPatient',
        }),
    },
}
</script>
